import React from 'react';
import "./Experience.css";
function Experience() {
    const videoUrl = "https://www.youtube.com/watch?v=OmizuGpm2sU";
  return (
    <div className='exp '>
        <div className='exp-top'>
            <h1>Experience Music Come Alive</h1>
            <p>Feel the rhythm, hear the detail. Our technology lets every note speak to your soul</p>
        </div>
        <div className='exp-down'>
            
                 {/* <video className='exp-video' src={videoUrl} controls autoPlay /> */}
                 {/* <iframe className='exp-video' src={videoUrl} title="YouTube Video" allow="autoplay; encrypted-media" allowFullScreen />
         */}
         <iframe className='exp-video' width="560" height="315" src="https://www.youtube.com/embed/OmizuGpm2sU" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

        </div>
    </div>
  )
}

export default Experience