import React from 'react';
import "./Partners.css";
import logo1 from "../../Asserts/companylogos/Company logo.png";
import logo2 from "../../Asserts/companylogos/Company logo (1).png";
import logo3 from "../../Asserts/companylogos/Company logo (2).png";
import logo4 from "../../Asserts/companylogos/Company logo (3).png";
import logo5 from "../../Asserts/companylogos/Company logo (4).png";
import logo6 from "../../Asserts/companylogos/Company logo (5).png";
import logo7 from "../../Asserts/companylogos/Company logo (6).png";
import logo8 from "../../Asserts/companylogos/6167fabdc5704190c2124693_logo-w 1.png";
import logo9 from "../../Asserts/companylogos/Hedera_Logo_PNG.png";
function Partners() {
    function openLink(url) {
        window.open(url, '_blank');
      }
  return (
    <div className='partners'>
        <div className='part-top'>
            <h1>Our Partners</h1>
            <p>Collaborating with our esteemed partners to drive innovation and excellence. Together, we bring you solutions that make a difference</p>
        </div>
        <div className='part-down'>
            {/* <div className='img-div-1'>
                <img src={logo1} alt='img'></img>
                <img src={logo2} alt='img'></img>
                <img src={logo3} alt='img'></img>
                <img src={logo4} alt='img'></img>
                <img src={logo5} alt='img'></img>
            </div> */}
            <div className='img-div-2'>
                {/* <img src={logo6} alt='img'></img>
                <img src={logo7} alt='img'></img> */}
                <img onClick={() => openLink('https://www.hbarfoundation.org/')} src={logo8} alt='img'></img>
                <img onClick={() => openLink('https://hedera.com/')} src={logo9} alt='img'></img>
            </div>

            
        </div>
        <div className='mobile-img'>
            {/* <div className='logo-img1'>
            <img src={logo1} alt='img'></img>
                <img src={logo2} alt='img'></img>
            </div>
            <div className='logo-img1'>
            <img src={logo3} alt='img'></img>
                <img src={logo4} alt='img'></img>
            </div>
            <div className='logo-img3'>
                 <img src={logo6} alt='img'></img>
                 <img src={logo7} alt='img'></img>
            </div> */}
            {/* <div className='logo-img4'>
                <img src={logo8} alt='img'></img>
                <img src={logo9} alt='img'></img>
            </div> */}
            <div className='logo-img4-new'>
                <img onClick={() => openLink('https://www.hbarfoundation.org/')} src={logo8} alt='img'></img>
                <img onClick={() => openLink('https://hedera.com/')} src={logo9} alt='img'></img>
            </div>
            
            {/* <div className='logo-img9'>
            <img src={logo5} alt='img'></img>
            </div> */}
                
               
            </div>
    </div>
  )
}

export default Partners