import React from 'react';
import "./Home.css";
// import charimg from "../../Asserts/Char-right.svg";
import charimg from "../../Asserts/char-right.png";
import mobiChar from "../../Asserts/Mask group.png";
//import backurl from "../../Asserts/Backurl.svg";
// import downarr from "../../Asserts/Arrowdown.svg"
import downarr from "../../Asserts/NewCharacters/Button.png"
function Home() {
  const scrolltoBottom = () =>{
    window.scrollTo({
      top: 1200, 
      behavior: 'smooth'
    });
  };
  return (
    <div className='home'>
      {/* <img src={backurl} alt="img"></img> */}
      
      

      <div className='home-div-right'>
        {/* <h1 className='home-h1'>DISCOVER <br></br> THE RHYTHM <br></br> OF YOUR SOUL</h1>
        <p className='home-p'> Immerse yourself in a world of music, where melodies come alive, artists shine, and your love for music finds its perfect harmony. </p>
        <div className='btn-div'>
          <button>Connect Now</button>
           <img src={downarr} alt="img"></img>
        </div>
        <div className='home-botdivs'>
          <div><h2>120K+</h2><p>Transactions</p></div>
          <div><h2>16M+</h2><p>Users</p></div>
          <div><h2>432K+</h2><p>CFT Rewards</p></div>
        </div> */}
        <div className='right-content'>
        <h1 className='home-h1'>DISCOVER <br></br> THE RHYTHM <br></br> OF YOUR SOUL</h1>
        <p className='home-p'> Immerse yourself in a world of music, where melodies come alive, artists shine, and your love for music finds its perfect harmony. </p>
        </div>
        <div className='btn-div'>
          {/* <button>Connect Now</button> */}
           <img onClick={scrolltoBottom} src={downarr} alt="img"></img>
        </div>
        <div className='home-botdivs'>
          {/* <div><h2>120K+</h2><p>Transactions</p></div>
          <div><h2>16M+</h2><p>Users</p></div>
          <div><h2>432K+</h2><p>CFT Rewards</p></div> */}
        </div>

      </div>
      <div className='home-div-left'>
        <img className='char-img' src={charimg} alt='img'></img>
        <img className='char-img2' src={mobiChar} alt='img'></img>
      </div>
    </div>
  )
}

export default Home