import React,{useState} from 'react';
import "./Characters.css";
//import ekamsing from "../../Asserts/Characters/EKamsing.svg";
//import bles from "../../Asserts/Characters/Bless.svg";
//import char3 from "../../Asserts/Characters/Char3.svg";
//import aafia from "../../Asserts/Characters/Aafia.svg";
//import char5 from "../../Asserts/Characters/hjv.svg";

import arrtop from "../../Asserts/Characters/ArrowTop.svg";
import arrdown from "../../Asserts/Characters/ArrowDown.svg";



//import ekamsing1 from "../../Asserts/Characters/Ekam Singh 1.png";

import ekamsing10 from "../../Asserts/Characters/Ekam Singh 10.png";
import char2 from "../../Asserts/Characters/Ekam Singh 6.png";
import char3 from "../../Asserts/Characters/Ekam Singh 7.png";
import char4 from "../../Asserts/Characters/Ekam Singh 8.png";
import char5 from "../../Asserts/Characters/Ekam Singh 9.png";

// import char1Big from "../../Asserts/BigCharacters/Avatar 1.png";
// import char2Big from "../../Asserts/BigCharacters/Avatar 2.png";
// import char3Big from "../../Asserts/BigCharacters/Avatar3.png";
// import char4Big from "../../Asserts/BigCharacters/Avatar 4.png";
// import char5Big from "../../Asserts/BigCharacters/Avatar 5.png";

import char1Big from "../../Asserts/NewCharacters/ekamsing.png";
import char2Big from "../../Asserts/NewCharacters/blessing.png";
import char3Big from "../../Asserts/NewCharacters/cara.png";
import char4Big from "../../Asserts/NewCharacters/aafia.png";
import char5Big from "../../Asserts/NewCharacters/david.png";

import char6Big from "../../Asserts/NewCharacters/zentamil.png";

import backlines from "../../Asserts/NewCharacters/Lines.svg"

function Characters() {
   const [charClick,setCharClick]=useState(1)
  return (
    <div className='char-page'>
        <div className='char-heading'>
            <h1>Choose Your Avatar</h1>
            <p>Embrace your digital identity. Select an avatar that captures the essence of you</p>
        </div>
        <div className='char-details-div'>
          <div className='select-img'>
            <img onClick={()=>setCharClick(charClick>1?charClick-1:1)} src={arrtop} alt='img'></img>
            <img onClick={()=>setCharClick(1)} className={charClick===1?"char-1-hov":"char-1"} src={ekamsing10} alt='img'></img>
            <img onClick={()=>setCharClick(2)} className={charClick===2?"char-1-hov":"char-1"} src={char2} alt='img'></img>
            <img onClick={()=>setCharClick(3)} className={charClick===3?"char-1-hov":"char-1"} src={char3} alt='img'></img>
            <img onClick={()=>setCharClick(4)} className={charClick===4?"char-1-hov":"char-1"} src={char4} alt='img'></img>
            <img onClick={()=>setCharClick(5)} className={charClick===5?"char-1-hov":"char-1"} src={char5} alt='img'></img>
            <img onClick={()=>setCharClick(charClick<5?charClick+1:5)}  src={arrdown} alt='img'></img>
          </div>
          <div className='charimage'>
          {
            charClick===1?<img src={char1Big} alt='img'></img>:
            charClick===2?<img src={char2Big} alt='img'></img>:
            charClick===3?<img src={char3Big} alt='img'></img>:
            charClick===4?<img src={char4Big} alt='img'></img>:
            charClick===5?<img src={char5Big} alt='img'></img>:""
          }
          {/* <img style={{position:"absolute",marginLeft:'-44%',
          marginTop:"-3%",height:"60%",width:"60%",zIndex:"-10"}} src={backlines} alt='img'></img> */}
          </div>

          <div className='char-details'>
            {charClick===1?
            <>
            <h1>Ekam Singh</h1>
            <p> Vocalist, plays guitar, harmonium, dhol and tabla, doesn’t prefer reading music and Cooking and graphic design</p>
            <button className='char-btn'>Know More</button>
            </>
          :
          charClick===2?
            <>
            <h1>BLESSING</h1>
            <p>Vocalist, plays drums and other types of percussions and African graffiti art, DIY crafts, brewing herbal remedies</p>
            <button className='char-btn'>Know More</button>
            </>
            :
          charClick===3?
          <>
          <h1>CARA</h1>
          <p>Vocalist, plays the piano, guitar and An eye for fashion and architecture</p>
          <button className='char-btn'>Know More</button>
          </>
          :
          charClick===4?
            <>
            <h1>AAFIA</h1>
            <p> Vocalists,  plays the erhu,dutar and Calligraphy, tight-rope walking, aerial dancing </p>
            <button className='char-btn'>Know More</button>
            </>
          :
          charClick===5?
            <>
            <h1>DAVÍD WOODSTOCK</h1>
            <p>Singer-songwriter, plays guitar, modular synths, keyboard and Baseball and  carpentry</p>
            <button className='char-btn'>Know More</button>
            </>
          
        :""
          }


          </div>
        </div>
    </div>
  )
}

export default Characters