import React from 'react';
import "./Footer.css";
import footimg from "../../Asserts/Footer/Metaband.png"
function Footer() {
  return (
    <div className='footer'>
        <div className='foot-img-div'>
            <img src={footimg} alt='img'></img>
            <p><span>Email :</span> info@arrimmersive.com</p>
        </div>
        {/* <div className='foot-line'></div> */}
        <div className='foot-end'>
            <p>© 2023 Metaband. All rights reserved.</p>
            <p>Terms  Privacy</p>
        </div>
    </div>
  )
}

export default Footer