
//import './App.css';
import Home from './components/Home/Home';
import Logosection from './components/Logosection/Logosection';
import Navbar from './components/Navbar/Navbar';
import Characters from './components/Characters/Characters';
import Experience from './components/Experience/Experience';
import Partners from './components/Partners/Partners';
import Footer from './components/Footer/Footer';
function App() {
  return (
    <div style={{overflowX:"hidden"}}>
    <Navbar></Navbar>
    <Home></Home>
    <Logosection></Logosection>
    <Characters></Characters>
    <Experience></Experience>
    <Partners></Partners>
    <Footer></Footer>
    </div>
  );
}

export default App;
