import React from 'react'
import img1 from "../../Asserts/Logos/Binance.png"
import img2 from "../../Asserts/Logos/yahoo.png"
import img3 from "../../Asserts/Logos/Investing.com.png"
import img4 from "../../Asserts/Logos/India Today.png"
import img5 from "../../Asserts/Logos/India Today (1).png"
import "./Logosection.css"
function Logosection() {
  function openLink(url) {
    window.open(url, '_blank');
  }
  return (
    <div className='contents'>
    <div className='logo-div'>
         <img onClick={() => openLink('https://www.binance.com/en-IN/feed/post/154368')} src={img1} alt="img"></img>
         <img onClick={() => openLink('https://finance.yahoo.com/news/slumdog-millionaire-composer-r-rahman-120513266.html')} src={img2} alt="img"></img>
         <img onClick={() => openLink('https://www.investing.com/news/cryptocurrency-news/ar-rahman-launches-katraar-in-partnership-with-hbar-foundation-2976330')} src={img3} alt="img"></img>
         <img onClick={() => openLink('https://www.indiatoday.in/cryptocurrency/story/ar-rahman-enters-the-world-of-web3-unveils-katraar-a-metaverse-platform-2319148-2023-01-09')} src={img4} alt="img"></img>
         <img onClick={() => openLink('https://www.news18.com/videos/ar-rahman-on-voice-cloning-and-metaverse-katraar-ar-rahman-metaverse-shorts-english-news-6990937.html')} src={img5} alt="img"></img>
    </div>
    </div>
  )
}

export default Logosection