import React from 'react'
import title from "../../Asserts/tiltle.svg";
import donut from "../../Asserts/Donut.svg"
import './Navbar.css'
function Navbar() {
  return (
    <div className='nav-div'>
        <img className='nav-img' src={title} alt='img'></img>
        <div className='nav-li'>
            <h5>Home</h5>
            <h5>Blog</h5>
            <h5>Contact Us</h5>
            <h5>Log In</h5>
            <h5>Sign Up</h5>
        </div>
        <img className='nav-donut' src={donut} alt="img"></img>
    </div>
  )
}

export default Navbar